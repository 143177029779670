import React, { Component } from 'react'

const initData = {
  heading: 'Marktstimmen',
  headingText:
    'Lesen Sie, was Verantwortungsträger im Schweizer Bankenmarkt über die Swiss Banking Map sagen.',
  headingTexttwo:
    'Lesen Sie, was Verantwortungsträger im Schweizer Bankenmarkt über die Swiss Banking Map sagen.'
}

const data = [
  {
    id: '1',
    avatorImg: '/img/eis.jpg',
    reviewer: 'Dr. Michael Eisenrauch',
    post: 'Mitglied der Konzernleitung BKB',
    text: 'Die Swiss Banking Map ist die umfangreichste Datensammlung zur Schweizer Finanzindustrie. Sie hilft mir, immer aktuell über die Marktgeschehnisse informiert zu bleiben.',
    comments: 'Die umfangreichste Datensammlung der Schweiz',
    quotIcon: '/img/quote.png',
    icon_1: 'fas fa-star text-warning',
    icon_2: 'fas fa-star text-warning',
    icon_3: 'fas fa-star text-warning',
    icon_4: 'fas fa-star text-warning',
    icon_5: 'fas fa-star text-warning'
  },
  {
    id: '2',
    avatorImg: '/img/ukn.jpg',
    reviewer: 'Urs Knecht',
    post: 'SPS Banking Schweiz',
    text: 'Als Provider müssen wir immer den Überblick über die neusten Entwicklungen behalten. Dank der Swiss Banking Map sind wir hier viel effizienter und dynamischer geworden.',
    comments: 'Die Finanzindustrie auf einen Blick',
    quotIcon: '/img/quote.png',
    icon_1: 'fas fa-star text-warning',
    icon_2: 'fas fa-star text-warning',
    icon_3: 'fas fa-star text-warning',
    icon_4: 'fas fa-star text-warning',
    icon_5: 'fas fa-star text-warning'
  },
  {
    id: '3',
    avatorImg: '/img/mli.jpg',
    reviewer: 'Matthias Liechti',
    post: 'CEO Clientis AG',
    text: 'Die Swiss Banking Map bietet eine Vielzahl an Analysen, Auswertungen und Mehrwertinhalten zu allen wesentlichen Banken und Providern der Schweiter Finanzindustrie.',
    comments: 'Geballte analytische Power - Up to date',
    quotIcon: '/img/quote.png',
    icon_1: 'fas fa-star text-warning',
    icon_2: 'fas fa-star text-warning',
    icon_3: 'fas fa-star text-warning',
    icon_4: 'fas fa-star text-warning',
    icon_5: 'fas fa-star text-warning'
  }
]

class ReviewSection extends Component {
  state = {
    initData: {},
    data: []
  }
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data
    })
  }
  render() {
    return (
      <section id="review" className="review-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">
                  {this.state.initData.heading}
                </h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.initData.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.initData.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`ro_${idx}`}
                  className="col-12 col-md-6 col-lg-4 res-margin"
                >
                  {/* Single Review */}
                  <div className="single-review card">
                    {/* Card Top */}
                    <div className="card-top p-4">
                      <div className="review-icon">
                        <i className={item.icon_1} />
                        <i className={item.icon_2} />
                        <i className={item.icon_3} />
                        <i className={item.icon_4} />
                        <i className={item.icon_5} />
                      </div>
                      <h4 className="text-primary mt-4 mb-3">
                        {item.comments}
                      </h4>
                      {/* Review Text */}
                      <div className="review-text">
                        <p>{item.text}</p>
                      </div>
                      {/* Quotation Icon */}
                      <div className="quot-icon">
                        <img className="avatar-md" src={item.quotIcon} alt="" />
                      </div>
                    </div>
                    {/* Reviewer */}
                    <div className="reviewer media bg-gray p-4">
                      {/* Reviewer Thumb */}
                      <div className="reviewer-thumb">
                        <img
                          className="avatar-lg radius-100"
                          src={item.avatorImg}
                          alt=""
                        />
                      </div>
                      {/* Reviewer Media */}
                      <div className="reviewer-meta media-body align-self-center ml-4">
                        <h5 className="reviewer-name color-primary mb-2">
                          {item.reviewer}
                        </h5>
                        <h6 className="text-secondary fw-6">{item.post}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default ReviewSection
