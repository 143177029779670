import React, { Component } from 'react'
import dat from '../../data/db.json'

class DiscoverSection extends Component {
  state = {
    data: {},
    discoverData: [],
    discoverIcon: []
  }
  componentDidMount() {
    const data = dat.bmDiscoverSection
    const discoverData = data.discoverData
    const discoverIcon = data.discoverIcon
    this.setState({
      data: data,
      discoverData: discoverData,
      discoverIcon: discoverIcon
    })
  }
  render() {
    return (
      <section className="section discover-area overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              {/* <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src={this.state.data.thumbOne} alt="" />
              </div> */}
              <div className="discover-icon pr-4">
                <span>
                  <i
                    style={{ color: this.state.data.color }}
                    className={this.state.data.iconClass}
                  />
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4 pb-sm-0">{this.state.data.heading}</h2>
                <p className="d-none d-sm-block pt-3 pb-4">
                  {this.state.data.headingText}
                </p>
                {/* Check List */}
                <ul className="check-list">
                  {this.state.discoverData.map((item, idx) => {
                    return (
                      <div key={`do_${idx}`}>
                        <li className="py-2">
                          {/* List Box */}
                          <div className="list-box media">
                            <span className="icon align-self-center">
                              <i className={item.iconClass} />
                            </span>
                            <span className="media-body pl-2">{item.text}</span>
                          </div>
                        </li>
                      </div>
                    )
                  })}
                </ul>
                <div className="icon-box d-flex mt-3">
                  {this.state.discoverIcon.map((item, idx) => {
                    return (
                      <div key={`il_${idx}`} className="service-icon pr-3">
                        <span>
                          <i className={item.iconClass} />
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <a href="/#contact" className="btn btn-bordered mt-4">
                {this.state.data.buttonText}
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DiscoverSection
