import React, { Component } from 'react'
import dat from '../../data/db.json'
class PricingSection extends Component {
  state = {
    data: {},
    pricingList: [],
    pricingData: []
  }
  componentDidMount() {
    const data = dat.bmPricingSection
    const pricingList = data.pricingList
    const pricingData = data.pricingData

    this.setState({
      data: data,
      pricingList: pricingList,
      pricingData: pricingData
    })
  }
  render() {
    return (
      <section
        id="pricing"
        className="section price-plan-area bg-gray overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2>{this.state.data.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.data.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.data.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-12">
              <div className="row price-plan-wrapper">
                {this.state.pricingData.map((item, idx) => {
                  return (
                    <div key={`p_${idx}`} className="col-12 col-md-4 mt-4">
                      {/* Single Price Plan */}
                      <div className="single-price-plan text-center p-5">
                        {/* Plan Thumb */}
                        <div className="plan-thumb pr-6">
                          <span>
                            <i
                              style={{ color: item.color }}
                              className={item.planImage}
                            />
                          </span>
                        </div>

                        {/* Plan Title */}
                        <div className="plan-title my-2 my-sm-3">
                          <h3 className="text-uppercase">{item.planTitle}</h3>
                        </div>
                        {/* Plan Price */}
                        <div className="plan-price pb-2 pb-sm-3">
                          <h1 className="color-primary">
                            <small className="fw-7">{item.priceSub}</small>
                            {item.planPrice}
                          </h1>
                        </div>
                        {/* Plan Description */}
                        <div className="plan-description">
                          <ul className="plan-features">
                            <li className="border-top py-3">{item.li_1}</li>
                            <li className="border-top py-3">{item.li_2}</li>
                            <li className="border-top py-3">{item.li_3}</li>
                            <li className="border-top py-3">{item.li_4}</li>
                            <li className="border-top py-3">{item.li_5}</li>
                            <li className="border-top py-3">{item.li_6}</li>
                            <li className="border-top py-3">{item.li_7}</li>
                            <li className="border-top py-3">{item.li_8}</li>
                            <li className="border-top py-3">{item.li_9}</li>
                            <li className="border-top border-bottom py-3">
                              {item.li_10}
                            </li>
                          </ul>
                        </div>
                        {/* Plan Button */}
                        {/* <div className="plan-button">
                                                    <a href="/#" className="btn mt-4">{item.planBtn}</a>
                                                </div> */}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-5">
            <p className="text-body pt-4 fw-5">
              {this.state.data.text}{' '}
              <a href="/#contact">{this.state.data.textLink}</a>
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default PricingSection
