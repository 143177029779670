import React, { Component } from 'react'
import dat from '../../data/db.json'

class VideoSection extends Component {
  state = {
    data: {},
    discoverData: [],
    discoverIcon: []
  }
  componentDidMount() {
    const data = dat.bmDiscoverSection
    const discoverData = data.discoverData
    const discoverIcon = data.discoverIcon
    this.setState({
      data: data,
      discoverData: discoverData,
      discoverIcon: discoverIcon
    })
  }
  render() {
    return (
      <section className="section video-area overflow-hidden bg-gray ptb_100">
        <div className="video-responsive">
          <iframe
            width="982"
            height="552"
            src={`https://www.youtube.com/embed/73ChTl5-Xls`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Swiss Banking Map"
          />
        </div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 order-1 order-lg-2"></div>
          </div>
        </div>
      </section>
    )
  }
}

export default VideoSection
