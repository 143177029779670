import React from 'react'
import Header from '../components/HeaderSection/Header'
import HeroSection from '../components/HeroSection/HeroOne'
import Counter from '../components/CounterSection/Counter'
import FeatureSection from '../components/Features/FeatureOne'
import ServiceSection from '../components/ServiceSection/ServiceOne'
import DiscoverSection from '../components/DiscoverSection/DiscoverOne'
import Work from '../components/WorkSection/Work'
import ScreenshotSection from '../components/ScreenshotSection/ScreenshotsOne'
import ReviewSection from '../components/ReviewSection/ReviewOne'
import PricingSection from '../components/PricingSection/PricingOne'
import FaqSection from '../components/FaqSection/FaqOne'
import Download from '../components/DownloadSection/Download'
import Team from '../components/TeamSection/Team'
import ContactSection from '../components/ContactSection/Contact'
import FooterSection from '../components/FooterSection/Footer'
import VideoSection from '../components/VideoSection/Video'
import ImprintSection from '../components/ImprintSection/Imprint'

import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'

function ThemeOne(props) {
  const useStyles = makeStyles({
    badge: {
      zIndex: 10000000,
      position: 'absolute',
      top: '22px',
      width: '180px',
      left: '50px'
    }
  })

  const classes = useStyles()

  return (
    <div>
      {/*====== Scroll To Top Area Start ======*/}
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>
      <div>
        {process.env.REACT_APP_BANKINGMAP_ENV !== 'production' ? (
          <Badge
            overlap="rectangular"
            className={classes.badge}
            badgeContent={
              'Landingpage env: ' + process.env.REACT_APP_BANKINGMAP_ENV
            }
            color="secondary"
          ></Badge>
        ) : (
          ''
        )}
      </div>

      {/*====== Scroll To Top Area End ======*/}
      <div className="main">
        <Header imageData={'/img/logo-white.png'} />
        <HeroSection />
        <Counter />
        {/* <VideoSection /> */}
        <FeatureSection />
        <ServiceSection />
        <DiscoverSection />
        <Work />
        <ScreenshotSection />
        {/*   <ReviewSection />*/}
        <PricingSection /> 
        <FaqSection />
        <Team />
        <Download />
        <ContactSection />
        <ImprintSection />
        <FooterSection />
      </div>
    </div>
  )
}

export default ThemeOne
