import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
const axios = require('axios')

function ContactForm(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  const myForm = React.createRef()

  const changHandler = (event) => {
    if (event.target.name === 'name') setName(event.target.value)
    if (event.target.name === 'email') setEmail(event.target.value)
    if (event.target.name === 'subject') setSubject(event.target.value)
    if (event.target.name === 'message') setMessage(event.target.value)
  }

  const submitHangler = (event) => {
    event.preventDefault()
    myForm.current.reset()
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')

    const url = `https://${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}`
    axios
      .post(`${url}/api/communication/sendForm`, {
        name: name,
        subject: subject,
        message: message,
        email: email
      })
      .then((data) => {
        setOpen(true)
        setTimeout(function () {
          setOpen(false)
        }, 2000)
      })
  }
  return (
    <div className="contact-box text-center">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        message="Ihre Nachricht wurde gesendet."
      />
      <form ref={myForm} onSubmit={submitHangler} className="contact-form">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required="required"
                onChange={changHandler}
                value={name}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required="required"
                onChange={changHandler}
                value={email}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Betreff"
                required="required"
                onChange={changHandler}
                value={subject}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Nachricht"
                required="required"
                onChange={changHandler}
                value={message}
              />
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-lg btn-block mt-3">
              <span className="text-white pr-3">
                <i className="fas fa-paper-plane" />
              </span>
              Nachricht senden
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
