import React from 'react'

import MyRouts from './routers/routes'

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  )
}

export default App
