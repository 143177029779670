import React, { Component } from 'react'
import Typography from 'react'
import dat from '../../data/db.json'
class FooterSection extends Component {
  state = {
    data: {},
    iconList: [],
    footerList_1: [],
    footerList_2: [],
    footerList_3: []
  }
  componentDidMount() {
    const data = dat.bmFooterSection
    const iconList = data.iconList
    const footerList_1 = data.footerList_1
    const footerList_2 = data.footerList_2
    const footerList_3 = data.footerList_3
    this.setState({
      data: data,
      iconList: iconList,
      footerList_1: footerList_1,
      footerList_2: footerList_2,
      footerList_3: footerList_3
    })
  }
  render() {
    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="footer-top bg-gray ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={this.state.data.image}
                        alt=""
                      />
                    </a>
                    <p className="mt-2 mb-3">{this.state.data.text}</p>
                    {/* Social Icons */}
                    <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a key={`fi_${idx}`} className={item.link} href="/#">
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href={item.link}>{item.text}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href={item.link}>{item.text}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_3}
                    </h3>
                    {/* Store Buttons */}
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {this.state.footerList_3.map((item, idx) => {
                        return (
                          <a key={`flth_${idx}`} href="/#">
                            <img src={item.image} alt="" />
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyright 2022 indivio AG. Alle Rechte vorbehalten.
                    </div>
                    <div className="copyright-middle">
                      {process.env.NODE_ENV === 'production' ? 'build ' + process.env.REACT_APP_CREATION_DATE : ''}
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right">
                      Made with <i className="fas fa-heart" /> by{' '}
                      <a href="/#">indivio</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default FooterSection
