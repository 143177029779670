import React from 'react'
import { createRoot } from 'react-dom/client'
//import ReactDOM from 'react-dom';
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

//ReactDOM.render(<App />, document.getElementById('root'));
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App />)
serviceWorker.unregister()
