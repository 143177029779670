import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'

// importing all the themes
import ThemeOne from '../themes/theme-one'

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<ThemeOne />} />
            {/* <Navigate to="/" /> */}
            <Route path="/" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
    )
  }
}
export default MyRouts
